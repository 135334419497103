import { React, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from "./static/HaloAiLogoNoBGCropped.png"
import { Card, CardContent, Button, Typography, Paper, Snackbar, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, } from "@mui/material";
import "./homepage.css"
import ReactCardFlip from "react-card-flip";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import Background from './Background';
import addSignups from './firebase';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styles from "./haloaicard.module.css"

export default function HomePage() {
  const [isFlipped, setIsFlipped] = useState(false);
  const [device, setDevice] = useState('Android');
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const handleClick = () => {
    setAlertOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process form submission here
    addSignups({ "name": name, "email": email, "deviceType": device })
    console.log("Form submitted", event);
    setName('');
    setEmail('');
    handleFlip();
    handleClick();
  };

  return (
    <>
      <Background />
      <div className='container-fluid content'>
        <div className='row align-items-center mb-4  main-container'>
          <div className="col-12 col-sm-12 col-md-5  text-sm-center text-md-end justify-content-sm-center">
            <img src={Logo} alt="Halo AI Logo" className="img-fluid logo  text-end text-md-end justify-content-end" />
          </div>
          {/* <div className='col-md-1'></div> */}
          <div className="col-12 col-md-3 pl-4 text-center">
            <Typography variant="h1" component="h1" style={{ fontSize: "5rem", color: "#3B4040", fontFamily: 'Chivo, Roboto, sans-serif' }}>
              Halo AI
            </Typography>
            {/* <Typography variant="h4" color="textSecondary" style={{ color: "#3B4040", fontFamily: 'Chivo, Roboto, sans-serif' }}>
              AI, for you.
            </Typography> */}
          </div>
        </div>
        <div >
          <ReactCardFlip isFlipped={isFlipped} flipDirection='vertical' infinite flipSpeedBackToFront={1} flipSpeedFrontToBack={1}>
            <Card className='info-card-front pt-2'>
              <CardContent className={styles.root}>
                <div className='row'>
                  <div className='col-md-12 mb-2 mb-md-0'>
                    <Paper className={`${styles.card} p-4`}>
                      <Typography variant="h4" className={styles.body} gutterBottom align="center">
                        Your Notes App, but with Superpowers!
                      </Typography>
                      {/* <Typography variant="h6" gutterBottom align="center" style={{ marginTop: 8 }}>
                        With Halo, your digital life will be a breeze.
                      </Typography> */}
                      <Typography variant="h5" className={styles.header} align="center" style={{ fontWeight: 300, marginTop: 16, marginBottom: 16 }}>
                        {/* <i> */}
                        Say goodbye to that note with all your reminders or that chat with yourself where you store important
                        reminders, only to forget about it.
                        {/* </i> */}
                      </Typography>
                      <Typography variant="h5" className={styles.header} align="center" style={{ fontWeight: 300, marginTop: 16, marginBottom: 16 }}>
                        {/* <i> */}
                        Just type/talk/take a screenshot, and let Halo take care of the rest.
                        {/* </i> */}
                      </Typography>
                      <Typography variant="h6" className={styles.header} align="center" style={{ fontWeight: 300, marginTop: 16, marginBottom: 16 }}>
                        <i>
                          It's that simple.
                        </i>
                      </Typography>
                    </Paper>
                  </div>
                  <div className='col-md-12'>
                    <Typography variant="overline" gutterBottom align="center" style={{ fontSize: "1.4em" }}>
                      Watch the Live Demo Here!
                    </Typography>
                    {/* <iframe width="1221" height="687" src="https://www.youtube.com/embed/pd6PFQMuF-w" title="Launching Halo AI!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}

                      <div className="video-responsive" style={{ borderRadius: 10 }}>
                        <iframe
                          style={{ borderRadius: 10 }}
                          width="90%"
                          height="570"
                          src="https://www.youtube.com/embed/pd6PFQMuF-w"
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                    </div>
                  </div>
                </div>
                <Button
                  className={`more-info-btn button mt-4 ${styles.button}`}
                  size='large'
                  onClick={handleFlip}
                  endIcon={<ArrowForwardIcon />}
                  variant="contained"
                  color="primary"
                >
                  <span><i>Count me in!</i></span>
                </Button>
              </CardContent>
            </Card>
            <Card className='info-card-back'>
              <CardContent>
                <Typography variant="h4" component="div" style={{ marginBottom: '20px', fontFamily: "Chivo, Roboto, sans-serif" }}>
                  Join Waitlist
                </Typography>
                <form onSubmit={handleSubmit} className='row' style={{ justifyContent: "space-around" }}>
                  <TextField
                    className='col-md-5'
                    label="Name"
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />

                  <TextField
                    className='col-md-5'
                    label="Email"
                    variant="outlined"
                    required
                    type="email"
                    style={{ marginBottom: '20px' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <FormControl component="fieldset" style={{ marginBottom: '20px', justifyContent: "center" }}>
                    <FormLabel component="legend">Which Device do you use?</FormLabel>
                    <RadioGroup
                      row
                      aria-label="device"
                      name="row-radio-buttons-group"
                      value={device}
                      onChange={(e) => setDevice(e.target.value)}
                      style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto" }}
                    >
                      <FormControlLabel
                        value="Android"
                        control={<Radio />}
                        label={<><AndroidIcon /> Android</>}
                      />
                      <FormControlLabel
                        value="iOS"
                        control={<Radio />}
                        label={<><AppleIcon /> iOS</>}
                      />
                    </RadioGroup>
                  </FormControl>
                  <div className='row justify-content-center'>
                    <Button className='join-waitlist-btn' type="submit" variant="contained" color="primary" size='large'>
                      I'm in!
                    </Button>
                    <Button className='go-back-btn' onClick={handleFlip} size='large'>
                      <ArrowBackIcon />
                    </Button>
                  </div>

                </form>

              </CardContent>
            </Card>
          </ReactCardFlip>
          <Snackbar
            open={alertOpen}
            autoHideDuration={5000}
            onClose={handleClose}
            message="Thank you for joining."
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            ContentProps={{
              style: {
                backgroundColor: '#4caf50',
                color: '#fff',
                fontSize: '16px',
                fontWeight: 'bold',
                borderRadius: '8px',
              }
            }}
          />
        </div>
      </div>
    </>
  )
}
