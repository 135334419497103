import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDcf0T1gRRT8VCMNChgcfwjhZlSUH7DtzQ",
    authDomain: "halo-ai-3c7a8.firebaseapp.com",
    projectId: "halo-ai-3c7a8",
    storageBucket: "halo-ai-3c7a8.appspot.com",
    messagingSenderId: "1085431444181",
    appId: "1:1085431444181:web:6524caffa3d823abff685c",
    measurementId: "G-6M738E3YXP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default async function addSignups(data) {
    try {
        await addDoc(collection(db, 'signups'), data);
        // alert('Data saved successfully!');
    } catch (error) {
        console.error('Error saving data: ', error);
        alert('Failed to save data.');
    }
}